.__dml .container {
	max-width: 700px;
	margin: 0 auto;
}

.heading {
	text-align: center;
}

.page-content {
	height: 90%;
	margin: 0 2%;
}