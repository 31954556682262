/* .songs-container {
	max-height: 80vh;
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
} */

.bnt-header {
	padding-top: 25px;
	color: var(--accent-color);
}

.best-new-tracks {
	background-color: rgb(36, 34, 34);
	max-width: 90vw;
	margin: 0 2vw;
	text-align: center;
}

.botp-logo {
	width: 90vw;
	margin-top: -185px;
	margin-bottom: -185px;
}

.botp-intro {
	text-align: center;
	font-size: 20px;
	padding: 100px 0;
}

.stay-tuned {
	text-align: center;
	padding: 25px;
}

.socials {
	text-align: center;
	margin-bottom: 30px;
}

.btn {
	background-color: blueviolet;
	width: 100px;
	height: 50px;
	border: none;
	margin: 0 10px;
}

.social-icon {
	margin: 0 10px;
}

@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3) {
		.botp-logo {
			width: 90vw;
			margin-top: 0;
			margin-bottom: -100px;
		}
}