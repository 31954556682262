.song-car {
	padding: 10px;
}

.song-details {
	display: flex;
  flex-direction: column;
	justify-content: center;
	padding: 5px;
}

.track-title {
	font-weight: 10000;
	font-size: 20px;
}

.track-artist {
	font-style: italic;
	font-weight: 500;
	color: var(--secondary-base);
}

.track-album {
	font-weight: 200;
	font-size: 15px;
	color: var(--secondary-base);
}
