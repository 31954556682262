.footer {
	height: 20px;
}

.footer-text {
	text-align: center;
}

.t-link {
	padding: 0 5px;
}