.__dml.header ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
}

.__dml.header ul li {
	margin: 0;
	padding: 0 2rem 0 0;
}